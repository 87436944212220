
















import { Component, Vue } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import { Authenticator, NavRouter } from '@/services';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { ModuleProvider } from '@/services';
import { SHENGCI_MODULES, VUE_LIFECYCLE_EVENT } from '@/enums';

@Component({
  components: {
    AsyncButton,
  }
})
export default class HomeView extends Vue
{  
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.MODULE_PROVIDER_INSTANCE)
  private module_provider!: ModuleProvider;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  public readonly module_type: SHENGCI_MODULES = SHENGCI_MODULES.HOME;

  mounted(): void {
    this.nav_router.pushModuleLifecycleEvent({module: this.module_type, event: VUE_LIFECYCLE_EVENT.MOUNTED});
  }
}
